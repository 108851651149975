import React from "react"
import { Paragraph, Row } from "../../../components/atomics"
import colors from "../../../colors"
import { useLanguage } from "../../../hooks"
import Page from "../../../components/Pages/Page"

const term = {
  title: {
    en: "Terms of use",
    pt: "Termos de uso",
  },
  s1: {
    en: "Overview",
    pt: "Visão Geral",
  },
  "s1.p1": {
    en:
    "The Flynow - Frases de Motivação app is a tool that aims to help people stay motivated to achieve their goals. Flynow is available for cell phones whose operating system is Android or iOS, through their official stores.",    pt:
      "O app Flynow - Frases de Motivação é um ferramenta que visa ajudar as pessoas a se manter motivadas a conquistarem seus objetivos. O Flynow está disponível para celulares cujo sistema operacional é Android ou iOS, atravéz das lojas oficiais das mesmas.",
  },
  "s1.p2": {
    en:
    "In the Flynow app, you can view different categories of phrases, such as motivation, inspiration, entrepreneurship, wisdom, reflection and others. You can save phrases in the gallery, share phrases, and mark phrases as favorites.",    pt:
      "No app Flynow é possível visualizar diversos categorias de frases, como motivação, inspiração, empreendedorismo, sabedoria, reflexão e outros. É possível salvar as frases na galeria, compartilhar frases, e marcar frases como favoritas.",
  },
  s2: {
    en: "Download App",
    pt: "Download do App",
  },
  "s2.p1": {
    en:
      "In order for the user to be able to download the application on his mobile device, he must make sure to download it from the official Android and iOS stores, that is, Play Store and App Store. The application is not officially distributed in other stores. ",
    pt:
      "Para que o usuário possa baixar o aplicativo em seu aparelho celular, deverá se certificar de fazer o Download nas Lojas oficiais do Android e iOS, ou seja, Play Store e App Store. O aplicativo não é distribuído de forma oficial em outras lojas.",
  },
  s3: {
    en: "Free and Premium Version",
    pt: "Versão Grátis e Premium",
  },
  "s3.p1": {
    en:
      "There is no cost to download the Flynow App, nor for any updates. In addition, most of the app's functions are available free of charge.",
    pt:
      "Para fazer o download do App Flynow não haverá custo nenhum, tampouco para eventuais atualizações. Além disso, a maioria das funções do aplicativo está disponível de forma gratuíta.",
  },
  "s3.p2": {
    en:
      "For the Premium version there will be a charge to the user who wants to use it, according to the plan that he decides to hire. It is not mandatory to use the Premium version, and it is up to the user to decide to obtain it for his mobile device.\nNo In the case of recurring plans, the user has access to the premium features while their subscription is active, that is, when the subscription expires, all the benefits of the premium version are automatically removed!\n On the other hand, when purchasing the Lifetime Plan, the user has access to the premium version of the app indefinitely without additional charges.",
    pt:
      "Para a versão Premium haverá uma cobrança ao usuário que queira utilizá-la, conforme o plano que o mesmo decidir contratar. Não é obrigatória a utilização da versão Premium, cabendo ao usuário a decisão de obter-lá para seu dispositivo móvel. \nNo caso dos planos recorrentes, o usuário possui acesso as funcionalidades premium enquanto tiver com a sua assinatura ativa, ou seja, quando a assinatura expirar, todos os benefícios da versão premium são removidos automaticamente!\n Por outro lado, ao adquirir o Plano Vitalício, o usuário tem acesso a versão premium do app por tempo indeterminado sem cobranças adicionais.",
  },
  s4: {
    en: "Registration and Use",
    pt: "Cadastro e Uso",
  },
  "s4.p1": {
    en:
      "The User authorizes Flynow to use, for an indefinite period, the information provided at the time of registration and during the use of the application, for statistical purposes and sending advertising material, newsletters, reports, etc.",
    pt:
      "O Usuário autoriza o Flynow a utilizar, por prazo indeterminado, as informações fornecidas no ato do cadastro e durante o uso do aplicativo, para fins estatísticos e envio de material publicitário, newsletters, informes, etc .",
  },
  "s4.p2": {
    en:
      "It is not the responsibility of Flynow if a User uses another user's registration.",
    pt:
      "Não é de responsabilidade do Flynow o caso de um Usuário se utilizar do cadastro de outro Usuário.",
  },
  "s4.p3": {
    en:
      "Flynow reserves the right to include, exclude or change the content and functionality of the application, as well as temporarily suspend or cancel it, at any time, regardless of prior notice to the User. Likewise, you can modify these Terms of Use, the most recent version of which will always be available for consultation in the application itself.",
    pt:
      "O Flynow se reserva o direito de incluir, excluir ou alterar os conteúdos e funcionalidades do aplicativo, bem como suspendê-lo temporariamente ou cancelá-lo, a qualquer momento, independentemente de aviso prévio ao Usuário. Da mesma forma, poderá modificar estes Termos de Uso, cuja versão mais recente estará sempre disponível para consulta no próprio aplicativo.",
  },
  "s4.p5": {
    en:
      "The user is aware that he may be removed from the platform if he does not comply with any of the terms of use.",
    pt:
      "O usuário está ciente que pode ser removido da plataforma caso não cumpra qualquer um dos termos de uso.",
  },

  s5: {
    en: "Warranty and Refund",
    pt: "Garantia & Reembolso",
  },
  "s5.p1": {
    en: "We offer a 7-day guarantee on the Annual and Lifetime Plans.",
    pt: "Oferecemos 7 dias de garantia nos Planos Anuais e Vitalício.",
  },
  "s5.p2": {
    en:
      "The guarantee is only valid on the user's first subscription, that is, if the user requests a refund once and then subscribes again, they will not be able to request a refund again.",
    pt:
      "A garantia somente é válida na primeira assinatura do usuário, ou seja, se o usuário pedir reembolso uma vez e depois assinar novamente, não poderá solicitar o reembolso novamente.",
  },
  "s5.p3": {
    en:
      "The refund is not carried out automatically, that is, the user needs to contact support by email requesting the refund.",
    pt:
      "O Reembolso não é realizado de forma automática, ou seja, o usuário precisa entrar em contato com o suporte por email solicitando o reembolso.",
  },
}

const privacy = {
  title: {
    en: "Privacy Policy",
    pt: "Política de Privacidade",
  },
  s1: {
    en: "Information Collection and Use",
    pt: "Coleta e uso de informações",
  },
  "s1.p1": {
    en:
      "When you register with Flynow we will ask you for information such as your name and email address. Your information is used only internally and will not be shared with others.",
    pt:
      "No ato do seu registro no Flynow nós lhe pedimos informações como seu nome e endereço de e-mail. Sua informação é utilizada apenas internamente e não será compartilhada com outros.",
  },
  "s1.p2": {
    en:
      "Flynow collects e-mail addresses from those who communicate with us by e-mail and through information sent in voluntary activities, such as participation in surveys. Flynow also collects application usage data without user identification. This usage data we collect is used to improve Flynow and the quality of the service provided",
    pt:
      "O Flynow coleta endereço de e-mail de quem se comunica conosco por e-mail e através de informações enviadas em atividades voluntárias, como por exemplo, participação em pesquisas. O Flynow também coleta dados de uso do aplicativo sem identificação do usuário. Esses dados de uso que coletamos é utilizado para melhorias do Flynow e qualidade do serviço prestado",
  },
  s2: {
    en: "Your data",
    pt: "Seus dados",
  },
  "s2.p1": {
    en:
      "Flynow uses business partners that provide hardware, software, networking, storage and related technology needed to run Flynow. Despite the code, database and all rights of the Flynow application; you have full rights to your own information. We will never share personal information with third parties without your prior authorization, and we will never sell your information to third parties.",
    pt:
      "Flynow utiliza parceiros comerciais que fornecem hardware, software, networking, armazenamento e tecnologia relacionada necessária para execução do Flynow. Apesar do código, banco de dados e todos os direitos do aplicativo Flynow; você tem total direito sobre sua própria informação. Nunca compartilharemos informações pessoais com terceiros sem sua prévia autorização e jamais venderemos suas informações para terceiros.",
  },
  s3: {
    en: "Security",
    pt: "Segurança",
  },
  "s3.p1": {
    en: "All information and data transmitted are secured by the SSL protocol.",
    pt:
      "Todas as informações e dados transmitidos são assegurados pelo protocolo SSL.",
  },
  s4: {
    en: "Changes",
    pt: "Mudanças",
  },
  "s4.p1": {
    en:
      "If our information practices change at any time, we will update this page. If you are concerned about how your information is used, you should check this page periodically.",
    pt:
      "Se nossas práticas com relação às informações mudarem em algum momento, nós atualizaremos essa página. Se você está preocupado sobre como sua informação é utilizada, você deverá checar essa página periodicamente.",
  },
}

const TermsOfUse = () => {
  const { language, setLanguage } = useLanguage()

  return (
    <Page
      title={'Flynow - Frases'}
      titlePage={term["title"][language]}
      logo={require(`../../../assets/images/app/frases/logo.png`)}
      description="Aplicativo Flynow - Frases de Motivação"
      pageId="app_phrases_terms"
    >
          <br/>
      <br  id="term"/>
      <Row
        className="column-in-mobile"
        style={{ justifyContent: "space-between" }}
      >
        <h1  style={{ marginBottom: 10 }}>{term["title"][language]}</h1>
        <Row style={{ alignItems: "center" }}>
          <h5 style={{ marginRight: 10 }}>Language:</h5>
          <button
            onClick={() => setLanguage("en")}
            style={{
              border: "none",
              padding: "5px 10px",
              marginRight: 10,
              background: language === "en" ? colors.degrade : "#aaa",
              color: "white",
              cursor: "pointer",
            }}
          >
            <h5>EN</h5>
          </button>
          <button
            onClick={() => setLanguage("pt")}
            style={{
              border: "none",
              padding: "5px 10px",
              marginRight: 10,
              background: language === "pt" ? colors.degrade : "#aaa",
              color: "white",
              cursor: "pointer",
            }}
          >
            <h5>PT</h5>
          </button>
        </Row>
      </Row>
      <br />
      <br />
      <h3>{term["s1"][language]}</h3>
      <Paragraph>
        {term["s1.p1"][language]}
        <br />
        {term["s1.p2"][language]}
      </Paragraph>
      <br />

      <h3>{term["s2"][language]}</h3>
      <Paragraph>{term["s2.p1"][language]}</Paragraph>

      <br />
      <h3>{term["s4"][language]}</h3>
      <Paragraph>{term["s4.p1"][language]}</Paragraph>
      <Paragraph>{term["s4.p2"][language]}</Paragraph>
      <Paragraph>{term["s4.p3"][language]}</Paragraph>
      <Paragraph>{term["s4.p5"][language]}</Paragraph>

      <br />
      <h3>{term["s3"][language]}</h3>
      <Paragraph>{term["s3.p1"][language]}</Paragraph>
      <Paragraph>{term["s3.p2"][language]}</Paragraph>

      <br />
      <h3>{term["s5"][language]}</h3>
      <Paragraph>{term["s5.p1"][language]}</Paragraph>
      <Paragraph>{term["s5.p2"][language]}</Paragraph>
      <Paragraph>{term["s5.p3"][language]}</Paragraph>

      <br/>

      <br id="privacy"  />
      <h1 style={{ marginBottom: 10 }}>{privacy["title"][language]}</h1>
      <br />
      <h3>{privacy["s1"][language]}</h3>
      <Paragraph>
        {privacy["s1.p1"][language]}
        <br />
        {privacy["s1.p2"][language]}
      </Paragraph>
      <br />

      <h3>{privacy["s2"][language]}</h3>
      <Paragraph>{privacy["s2.p1"][language]}</Paragraph>

      <br />
      <h3>{privacy["s3"][language]}</h3>
      <Paragraph>{privacy["s3.p1"][language]}</Paragraph>

      <br />
      <h3>{privacy["s4"][language]}</h3>
      <Paragraph>{privacy["s4.p1"][language]}</Paragraph>
    </Page>
  )
}

export default TermsOfUse
